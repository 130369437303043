<template>
  <div class="page">
    <div class="main">
      <div class="title">新闻列表</div>

      <!--搜索start-->
      <el-row style="margin-top: 30px">
        <el-input v-model="title" placeholder="根据标题搜索" style="width: 150px" />
        <el-select v-model="type" clearable class="filter-item" placeholder="请选择资讯分类" style="width: 150px">
          <el-option :key="1" label="高校新闻" :value="1" />
          <el-option :key="0" label="高考资讯" :value="0" />
          <el-option :key="2" label="系统资讯" :value="2" />
          <el-option :key="3" label="鹿鸣动态" :value="3" />
        </el-select>
        <el-select v-model="art_type" clearable class="filter-item" placeholder="请选择艺术分类" style="width: 150px">
          <el-option :key="1" label="艺术类" :value="1" />
          <el-option :key="2" label="体育类" :value="2" />
          <el-option :key="3" label="专项计划" :value="3" />
          <el-option :key="4" label="保送生" :value="4" />
          <el-option :key="5" label="强基计划" :value="5" />
          <el-option :key="6" label="艺考攻略" :value="6" />
        </el-select>
        <el-button type="primary" @click="getList">搜索</el-button>
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </el-row>
      <!--搜索end-->

      <!-- 表格start-->
      <el-row style="margin-top: 10px; min-height: 580px">
        <el-table :key="tables.tableKey" v-loading="tables.listLoading" :data="tables.tableData" border
          highlight-current-row style="width: 100%" height="580">
          <el-table-column type="index" label="序号" width="50" align="center" />
          <el-table-column prop="title" label="新闻名称" width="180" show-overflow-tooltip />
          <el-table-column prop="source" label="来源" show-overflow-tooltip />
          <el-table-column prop="brief" label="简介" width="180" show-overflow-tooltip />
          <el-table-column prop="type" label="分类" width="100" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.type === 1">高校新闻 </el-tag>
              <el-tag v-if="scope.row.type === 0">高考资讯 </el-tag>
              <el-tag v-if="scope.row.type === 2">系统资讯 </el-tag>
              <el-tag v-if="scope.row.type === 3">鹿鸣动态 </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="art_type" label="艺术分类" width="100" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.art_type === 1">艺术类 </el-tag>
              <el-tag v-if="scope.row.art_type === 2">体育类 </el-tag>
              <el-tag v-if="scope.row.art_type === 3">专项计划 </el-tag>
              <el-tag v-if="scope.row.art_type === 4">保送生 </el-tag>
              <el-tag v-if="scope.row.art_type === 5">强基计划 </el-tag>
              <el-tag v-if="scope.row.art_type === 6">艺考攻略 </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" width="80" align="center">
            <template slot-scope="scope">
              <el-tag :type="scope.row.status == 0 ? 'danger' : 'success'" disable-transitions>{{ scope.row.status == 0
                  ? "隐藏" : "显示"
              }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="click_num" label="点击" show-overflow-tooltip />

          <el-table-column prop="create_time" label="添加时间" show-overflow-tooltip />
          <el-table-column label="操作" width="auto" fixed="right" min-width="150px">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleEdit(scope.row.id)">编辑
              </el-button>
              <el-button size="mini" type="primary" @click="handleDel(scope.row.id)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <template>
            <div class="block">
              <el-pagination layout="prev, pager, next" :current-page="tables.currentPage" :total="tables.total"
                @current-change="handleCurrentChange" />
            </div>
          </template>
        </div>
      </el-row>
      <!--  表格end-->

      <!--    表单提交-->
      <el-dialog :title="dialog.title" :visible.sync="dialog.centerDialogVisible" :close-on-click-modal="false"
        width="65%" @closed="resetForm">
        <el-form ref="dataForm" :inline="true" :model="formData" label-position="right" label-width="100px"
          style="padding-left: 40px">
          <el-form-item label="新闻名称" prop="title">
            <el-input v-model="formData.title" style="width: 300px" />
          </el-form-item>
          <el-form-item label="新闻简介" prop="brief">
            <el-input v-model="formData.brief" style="width: 300px" />
          </el-form-item>
          <el-form-item label="新闻来源" prop="source">
            <el-input v-model="formData.source" style="width: 300px" />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="formData.status" class="filter-item" placeholder="是否显示" style="width: 300px">
              <el-option :key="1" label="显示" :value="1" />
              <el-option :key="0" label="隐藏" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="添加时间" prop="create_time">
            <el-input v-model="formData.create_time" style="width: 300px" />
          </el-form-item>
          <el-form-item label="分类" prop="type">
            <el-select v-model="formData.type" clearable class="filter-item" placeholder="是否显示" style="width: 300px">
              <el-option :key="1" label="高校新闻" :value="1" />
              <el-option :key="0" label="高考资讯" :value="0" />
              <el-option :key="'2'" label="系统资讯" :value="'2'" />
              <el-option :key="'3'" label="鹿鸣动态" :value="'3'" />
            </el-select>
          </el-form-item>
          <el-form-item label="艺术分类" prop="art_type">
            <el-select v-model="formData.art_type" clearable class="filter-item" placeholder="是否显示"
              style="width: 300px">
              <el-option :key="0" label="请选择艺术分类" :value="0" />
              <el-option :key="1" label="艺术类" :value="1" />
              <el-option :key="'2'" label="体育类" :value="'2'" />
              <el-option :key="'3'" label="专项计划" :value="'3'" />
              <el-option :key="'4'" label="保送生" :value="'4'" />
              <el-option :key="'5'" label="强基计划" :value="'5'" />
              <el-option :key="'6'" label="艺考攻略" :value="'6'" />
            </el-select>
          </el-form-item>
          <br />
          <el-form-item label="新闻详情">
            <div class="edit_container">
              <vue-tinymce v-if="dialog.centerDialogVisible" v-model="formData.maincontent" :setting="setting" />
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetForm">取 消</el-button>
          <el-button v-if="dialog.title == '编辑'" @click="edit">确定</el-button>
          <el-button v-if="dialog.title == '新增'" @click="add">添加</el-button>
        </span>
      </el-dialog>
      <!--    表单提交-->
    </div>
  </div>
</template>

<script>
import { VueTinymce } from '@packy-tang/vue-tinymce'
export default {
  name: "",
  components: {
    VueTinymce
  },

  data() {
    return {
      type: null,
      art_type: null,
      title: null,
      tables: {
        tableKey: 0,
        listLoading: false,
        currentPage: 1,
        pageSize: 10,
        total: 1,
        tableData: [],
      },
      setting: {

      },
      dialog: {
        centerDialogVisible: false,
      },
      formData: {
        id: "",
        title: "",
        maincontent: "",
        brief: "",
        status: 1,
        source: "",
        type: null,
        art_type: null,
        create_time: "",
      },
      dialogVisible: false,
    };
  },
  created() {
    this.getList();
  },


  methods: {
    getList() {
      this.tables.listLoading = true;
      const data = {
        title: this.title,
        art_type: this.art_type,
        type: this.type,
        pageSize: this.tables.pageSize,
        pageNum: this.tables.currentPage,
      };
      this.$fecth.post("news/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.tables.tableData = data.lists;
          this.tables.pageSize = data.pageSize;
          this.tables.total = data.total;
          this.tables.currentPage = data.pageNum;
        } else {
          this.showTips(0, msg);
        }
        setTimeout(() => {
          this.tables.listLoading = false;
        }, 0.3 * 1000);
      });
    },
    handleEdit(id) {
      this.dialog.title = "编辑";
      this.formData.id = id;
      const data = {
        id: id,
      };
      this.$fecth.post("news/getDataById", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.formData = data;
          this.dialog.centerDialogVisible = true;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    edit() {
      const data = {
        id: this.formData.id,
        title: this.formData.title,
        maincontent: this.formData.maincontent,
        brief: this.formData.brief,
        source: this.formData.source,
        status: this.formData.status,
        type: this.formData.type,
        art_type: this.formData.art_type,
        create_time: this.formData.create_time
      }
      this.$fecth.post("news/edit", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, msg);
          this.resetForm();
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleAdd() {
      this.dialog.title = "新增";
      this.dialog.centerDialogVisible = true;
    },
    add() {
      const data = {
        title: this.formData.title,
        maincontent: this.formData.maincontent,
        brief: this.formData.brief,
        source: this.formData.source,
        status: this.formData.status,
        type: this.formData.type,
        art_type: this.formData.art_type,
        create_time: this.formData.create_time,
      };
      this.$fecth.post("news/add", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, msg);
          this.resetForm();
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleDel(id) {
      const data = {
        id: id,
      };
      this.$fecth.post("news/delete", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, msg);
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    resetForm() {
      this.formData = {
        id: "",
        title: "",
        maincontent: "",
        brief: "",
        source: "",
        status: 1,
        type: null,
        art_type: null,
      };
      this.dialog.centerDialogVisible = false;
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },

    handleCurrentChange(val) {
      this.tables.currentPage = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang='less'>
</style>
